import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import api from 'Common/api'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import {
  Alert,
  Button,
  Col,
  Drawer,
  Dropdown,
  Icon,
  List,
  Menu,
  message,
  Row,
  Tag,
  Tooltip,
} from 'antd'
import styled from 'styled-components'
import AgDateCellEditor from 'Components/NewCellEditors/AgDateCellEditor'
import AgListCellEditor from 'Components/NewCellEditors/AgListCellEditor'
import { css } from '@emotion/css'
import { AG_GRID_LOCALE_IT } from 'Common/ag-grid.locale'
import moment from 'moment'
import { useSelector } from 'react-redux'
import AgTextCellEditor from 'Components/NewCellEditors/AgTextCellEditor'
import AgNumberCellEditor from 'Components/NewCellEditors/AgNumberCellEditor'
import { cloneDeep } from 'lodash'
import DossierUnicoDataToolbar from 'Components/DossierUnico/DossierUnicoDataToolbar'
import './DossierUnico.css'
import DossierUnicoUtils from 'Components/DossierUnico/DossierUnicoUtils'
import SearchProductsModal from 'Components/DossierUnico/SearchProductsModal'
import { ROLE_CP, ROLE_CS } from 'Common/constants/global'

const flexClass = css`
  display: flex;
  flex-direction: column;
`

export const CellBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
`

const imageRenderer = params => {
  const { value, data } = params

  return (
    <CellBox style={{ justifyContent: 'center' }}>
      <img
        src={`https://media.adeo.com/media/${value}/filename.jpg?width=50&height=50`}
        alt={`Immagine prodotto ${data.idReferenza}`}
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </CellBox>
  )
}

const stateRenderer = params => {
  return (
    <CellBox style={{ justifyContent: 'center' }}>
      <Tag color={DossierUnicoUtils.getColorByState(params.value)}>
        {params.value}
      </Tag>
    </CellBox>
  )
}

const inoutRenderer = params => {
  if (!params.data.added && !params.data.removed) {
    return '';
  }

  return (
    <CellBox style={{ justifyContent: 'center' }}>
      <Tag color={params.data.added ? 'green' : params.data.removed ? 'red' : ''}>
        {params.data.added ? 'IN' : params.data.removed ? 'OUT' : ''}
      </Tag>
    </CellBox>
  )
}

const dateFormatter = params => {
  if (!params.value) return null
  const date = moment(params.value)
  return date.format('DD/MM/YYYY')
}

const isFieldEditable = params => {
  if (typeof params.colDef.editable === 'function') {
    return params.colDef.editable(params)
  }

  return params.colDef.editable === true
}

const booleanFilterFormatter = params => {
  return params.value ? 'Si' : 'No'
}

const DossierUnicoData = ({ dossier, hideHeader, setHideHeader }) => {
  const [records, setRecords] = useState([])
  const [dataChanges, setDataChanges] = useState([])
  const [selectedRecords, setSelectedRecords] = useState([])
  const originalRecords = useRef([])
  const gridApi = useRef(null)
  const columnApi = useRef(null)
  const workingRecord = useRef(null)
  const user = useSelector(state => state.User)
  const [showProductsModal, setShowProductsModal] = useState(false)
  const [showProductErrorsModal, setProductErrorsModal] = useState(false)
  const [productIssues, setProductIssues] = useState({})

  useEffect(() => {
    api.dossierUnico.getAllProducts(dossier.id).then(response => {
      setRecords(response.data)
      originalRecords.current = cloneDeep(response.data)
    })
  }, [dossier])

  const refreshProductIssues = useCallback(() => {
    if (!user) return
    const issuesMap = records.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.idReferenza]: {
          record: curr,
          issues: DossierUnicoUtils.getRecordIssues(curr, { user }),
        },
      }),
      {},
    )
    setProductIssues(issuesMap)
  }, [user, records]);

  useEffect(() => {
    refreshProductIssues();
  }, [records, user])

  const onGridReady = useCallback(params => {
    params.columnApi.autoSizeAllColumns()

    gridApi.current = params.api
    columnApi.current = params.columnApi
  }, [])

  const onModelUpdated = useCallback(params => {
    params.columnApi.autoSizeAllColumns()
  }, [])

  const defaultCellRenderer = useCallback(
    params => {
      const fieldWarning = DossierUnicoUtils.getFieldWarnings(
        params.colDef.field,
        params.data,
        { user },
      )
      const fieldError = DossierUnicoUtils.getFieldErrors(
        params.colDef.field,
        params.data,
        { user },
      )
      return (
        <CellBox>
          {fieldWarning.length > 0 && fieldError.length === 0 && (
            <Tooltip
              title={fieldWarning.map((line, index) => (
                <>
                  {index > 0 ? <br /> : null}
                  {line}
                </>
              ))}>
              <Icon
                type="warning"
                theme="outlined"
                style={{ marginRight: '8px', color: '#FF9966' }}
              />
            </Tooltip>
          )}
          {fieldError.length > 0 && (
            <Tooltip
              title={fieldError.map((line, index) => (
                <>
                  {index > 0 ? <br /> : null}
                  {line}
                </>
              ))}>
              <Icon
                type="exclamation-circle"
                theme="outlined"
                style={{ marginRight: '8px', color: '#fc3939' }}
              />
            </Tooltip>
          )}
          {isFieldEditable(params) && (
            <Icon type="edit" theme="twoTone" style={{ marginRight: '8px' }} />
          )}
          {params.formatValue ? params.formatValue(params.value) : params.value}
        </CellBox>
      )
    },
    [user],
  )

  const handleRowActionClick = useCallback(
    async (key, params) => {
      switch (key) {
        case 'out':
          params.node.setDataValue('removed', true)
          break

        case 'activate':
          params.node.setDataValue('removed', false)
          break

        case 'delete':
          await api.dossierUnico.deleteProducts(dossier.id, [
            params.data.idReferenza,
          ])
          gridApi.current.applyTransaction({
            remove: [{ idReferenza: params.data.idReferenza }],
          })
          break

        case 'inout':
          workingRecord.current = params.node
          setShowProductsModal(true)
          break

        default:
      }
    },
    [dossier.id],
  )

  const actionsMenuCellRenderer = useCallback(
    params => {
      const menu = (
        <Menu onClick={({ key }) => handleRowActionClick(key, params)}>
          {!params.data.added &&
            !params.data.removed &&
            user?.role === ROLE_CP && (
              <Menu.Item key="out">
                <Icon type="close-square" />
                Out
              </Menu.Item>
            )}
          {params.data.removed && user?.role === ROLE_CP && (
            <Menu.Item key="activate">
              <Icon type="rollback" />
              Riattiva
            </Menu.Item>
          )}
          {!params.data.added &&
            !params.data.removed &&
            user?.role === ROLE_CP && (
              <Menu.Item key="inout">
                <Icon type="swap" />
                In/Out
              </Menu.Item>
            )}
          {DossierUnicoUtils.canDeleteRecord(params.data) &&
            user?.role === ROLE_CP && (
              <Menu.Item key="delete">
                <Icon type="delete" />
                Elimina
              </Menu.Item>
            )}
        </Menu>
      )

      return (
        <Dropdown overlay={menu} trigger="click">
          <Button shape="circle" icon="more" />
        </Dropdown>
      )
    },
    [handleRowActionClick, user?.role],
  )

  const gridColumns = useMemo(
    () => [
      {
        sortable: false,
        filter: false,
        cellRenderer: actionsMenuCellRenderer,
        pinned: 'left',
        suppressColumnsToolPanel: true,
        suppressNavigable: true,
        suppressKeyboardEvent: true,
        suppressPaste: true,
        suppressFillHandle: true,
        suppressMovable: true,
        suppressMenu: true,
        suppressFiltersToolPanel: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        width: 50,
        cellStyle: {
          padding: 0,
          margin: 0,
          width: '50px !important',
        },
      },
      {
        headerName: 'Referenza',
        field: 'idReferenza',
        sort: 'asc',
        pinned: 'left',
        checkboxSelection: true,
      },
      {
        headerName: 'Descrizione',
        field: 'anagraficaDescrizione',
        pinned: 'left',
      },
      {
        headerName: 'Immagine',
        field: 'anagraficaFoto',
        cellRenderer: imageRenderer,
      },
      {
        headerName: 'Stato',
        field: 'stato',
        valueGetter: params =>
          DossierUnicoUtils.translateState(params.data.stato),
        cellRenderer: stateRenderer,
      },
      { headerName: 'In/Out', editable: false, cellRenderer: inoutRenderer, valueGetter: params => params.data.added ? 'IN' : params.data.removed ? 'OUT' : null },
      { headerName: 'Tipo', field: 'anagraficaNomeTipo' },
      { headerName: 'Sottotipo', field: 'anagraficaNomeSottotipo' },
      {
        headerName: 'Impianto Da',
        field: 'impiantoDa',
        editable: DossierUnicoUtils.canEdit('impiantoDa', user, {}),
        cellEditor: AgDateCellEditor,
        valueFormatter: dateFormatter,
        cellEditorParams: {
          disabledDate: (current, params) => {
            const lowLimit = moment().add(38, 'days')

            if (current.isBefore(lowLimit)) return true

            const impiantoA = moment(params.data.impiantoA)

            return !!(impiantoA.isValid() && current.isAfter(impiantoA))
          },
        },
      },
      {
        headerName: 'Impianto A',
        field: 'impiantoA',
        editable: DossierUnicoUtils.canEdit('impiantoA', user, {}),
        cellEditor: AgDateCellEditor,
        valueFormatter: dateFormatter,
        cellEditorParams: {
          disabledDate: (current, params) => {
            const lowLimit = moment().add(39, 'days')

            if (current.isBefore(lowLimit)) return true

            const impiantoDa = moment(params.data.impiantoDa)

            return !!(
              impiantoDa.isValid() && current.isSameOrBefore(impiantoDa)
            )
          },
        },
      },
      {
        headerName: 'Completamento Negozio Da',
        field: 'completamentoNegozioDa',
        editable: DossierUnicoUtils.canEdit('completamentoNegozioDa', user, {}),
        cellEditor: AgDateCellEditor,
        valueFormatter: dateFormatter,
        cellEditorParams: {
          disabledDate: (current, params) => {
            const impiantoDa = moment(params.data.impiantoDa)

            if (!impiantoDa.isValid()) return true

            return (
              current.isBefore(moment().add(1, 'day'), 'day') ||
              current.isAfter(impiantoDa.clone().subtract(37, 'days'), 'day')
            )
          },
        },
      },
      {
        headerName: 'Completamento Negozio A',
        field: 'completamentoNegozioA',
        editable: DossierUnicoUtils.canEdit('completamentoNegozioA', user, {}),
        cellEditor: AgDateCellEditor,
        valueFormatter: dateFormatter,
        cellEditorParams: {
          disabledDate: (current, params) => {
            const impiantoDa = moment(params.data.impiantoDa)

            if (!impiantoDa.isValid()) return true

            const completamentoNegozioDa = moment(
              params.data.completamentoNegozioDa,
            )

            if (!completamentoNegozioDa.isValid()) return true

            return (
              current.isBefore(completamentoNegozioDa) ||
              current.isAfter(impiantoDa.clone().subtract(37, 'days'), 'day')
            )
          },
        },
      },
      {
        headerName: 'Impianto Effettivo',
        field: 'impiantoEffettivo',
        editable: params =>
          DossierUnicoUtils.canEdit('impiantoEffettivo', user, params.data),
        cellEditor: AgDateCellEditor,
        valueFormatter: dateFormatter,
      },
      { headerName: 'Gamma', field: 'anagraficaGamma' },
      {
        headerName: 'Gamma Futura',
        field: 'gamma',
        editable: params =>
          DossierUnicoUtils.canEdit('gamma', user, params.data),
        cellEditor: AgListCellEditor,
        cellEditorParams: {
          options: [
            { value: 'A', label: 'A' },
            { value: 'B', label: 'B' },
            { value: 'C', label: 'C' },
            { value: 'D', label: 'D' },
            { value: 'L', label: 'L' },
            { value: 'R', label: 'R' },
            { value: 'S', label: 'S' },
          ],
        },
      },
      { headerName: 'Precotop Reappro', field: 'anagraficaPrecotopReappro' },
      {
        headerName: 'Precotop Reappro Futuro',
        field: 'precotopReappro',
        editable: params =>
          DossierUnicoUtils.canEdit('precotopReappro', user, params.data),
        cellEditor: AgListCellEditor,
        cellEditorParams: {
          options: [
            { value: 1, label: '1' },
            { value: 2, label: '2' },
          ],
        },
      },
      {
        headerName: 'Top Reappro',
        field: 'topReappro',
        editable: params =>
          DossierUnicoUtils.canEdit('topReappro', user, params.data),
        valueGetter: params =>
          DossierUnicoUtils.computeStoreValue(params.data, 'topReappro', user?.store, params.data.precotopReappro),
        valueSetter: params => {
          params.data.topReappro = {
            ...params.data.topReappro,
            [user?.store]: params.newValue,
          }
          return true
        },
        cellEditor: AgListCellEditor,
        hide: user?.role !== 'CS',
        cellEditorParams: {
          options: [
            { value: 1, label: '1' },
            { value: 2, label: '2' },
          ],
        },
      },
      {
        headerName: 'Facing S',
        field: 'facingA',
        editable: params =>
          DossierUnicoUtils.canEdit('facingA', user, params.data),
        cellEditor: AgNumberCellEditor,
      },
      {
        headerName: 'Facing M',
        field: 'facingAb',
        editable: params =>
          DossierUnicoUtils.canEdit('facingAb', user, params.data),
        cellEditor: AgNumberCellEditor,
      },
      {
        headerName: 'Facing L',
        field: 'facingAbc',
        editable: params =>
          DossierUnicoUtils.canEdit('facingAbc', user, params.data),
        cellEditor: AgNumberCellEditor,
      },
      {
        headerName: 'Cluster',
        field: 'cluster',
        hide: user?.role !== ROLE_CS,
        editable: params =>
          DossierUnicoUtils.canEdit('cluster', user, params.data),
        valueFormatter: params => DossierUnicoUtils.getLabelForSelectedCluster(params.value),
        valueGetter: params =>
          DossierUnicoUtils.computeStoreValue(params.data, 'cluster', user?.store),
        valueSetter: params => {
          params.data.cluster = {
            ...params.data.cluster,
            [user?.store]: params.newValue,
          }
          return true
        },
        cellEditor: AgListCellEditor,
        cellEditorParams: {
          options: [
            { value: 'a', label: 'S' },
            { value: 'ab', label: 'M' },
            { value: 'abc', label: 'L' },
          ],
        },
      },
      {
        headerName: 'Facing',
        field: 'facingStore',
        hide: user?.role !== ROLE_CS,
        valueGetter: params => DossierUnicoUtils.computeStoreValue(params.data, 'facingStore', user?.store),
        valueSetter: params => {
          params.data.facingStore = {
            ...params.data.facingStore,
            [user?.store]: params.newValue,
          }
          return true
        },
        editable: params => DossierUnicoUtils.canEdit('facingStore', user, params.data),
        cellEditor: AgNumberCellEditor,
      },
      {
        headerName: 'Num PDD',
        field: 'numPdd',
        editable: params =>
          DossierUnicoUtils.canEdit('numPdd', user, params.data),
        cellEditor: AgNumberCellEditor,
      },
      {
        headerName: 'Stock Presentazione',
        field: 'stock',
        editable: params =>
          DossierUnicoUtils.canEdit('stock', user, params.data),
        cellEditor: AgNumberCellEditor,
      },
      { headerName: 'Venduto Annuo (QTA)', field: 'anagraficaVendutoAnnuo' },
      {
        headerName: 'Prev Venduto Annuo',
        field: 'prevVendutoAnnuo',
        editable: params =>
          DossierUnicoUtils.canEdit('prevVendutoAnnuo', user, params.data),
        cellEditor: AgNumberCellEditor,
      },
      {
        headerName: 'Ref Sostituita',
        field: 'idRefSostituita',
        editable: params =>
          DossierUnicoUtils.canEdit('idRefSostituita', user, params.data),
        cellEditor: AgTextCellEditor,
      },
      {
        headerName: 'Tipo Sostituzione',
        field: 'tipoSostituzione',
        editable: params =>
          DossierUnicoUtils.canEdit('tipoSostituzione', user, params.data),
        cellEditor: AgListCellEditor,
        cellEditorParams: {
          options: [
            { value: 'sostituita', label: 'Sostituita' },
            { value: 'similare', label: 'Similare' },
          ],
        },
      },
      { headerName: 'Data AVS Ref Sostituita', field: 'dataAvsRefSostituita' },
      { headerName: 'Reparto', field: 'anagraficaNomeReparto' },
      { headerName: 'Sottoreparto', field: 'anagraficaNomeSottoreparto' },
      { headerName: 'EAN', field: 'anagraficaEan' },
      { headerName: 'Stato Ref', field: 'anagraficaStato' },
      {
        headerName: 'Data Attivo Commercio',
        field: 'anagraficaDataAttivoCommercio',
        valueFormatter: dateFormatter,
      },
      { headerName: 'Data AVS', field: 'anagraficaDataAvs' },
      { headerName: 'Prezzo Vendita', field: 'anagraficaPv' },
      { headerName: 'Fornitore', field: 'anagraficaNomeFornitore' },
      { headerName: 'Top 2500', field: 'anagraficaTop2500' },
      { headerName: 'Top EM', field: 'anagraficaTopEm' },
      { headerName: 'Top Expo', field: 'anagraficaTopExpo' },
      { headerName: 'Top MDH', field: 'anagraficaTopMdh' },
      { headerName: 'Quartili', field: 'anagraficaQuartili' },
      { headerName: 'Condizionamento', field: 'condizionamento' },
      { headerName: 'Tipo Imballo', field: 'anagraficaTipoImballo' },
      {
        headerName: 'Dimensioni Imballo',
        field: 'anagraficaDimensioniImballo',
      },
      { headerName: 'Dimensioni Ref', field: 'anagraficaDimensioniRef' },
      { headerName: 'Classement', field: 'anagraficaClassement' },
      { headerName: 'Tipo Gestione Ordini', field: 'anagraficaModGestion' },
      { headerName: 'Unità Capienza', field: 'anagraficaUnitaCapienza' },
      {
        headerName: 'Quantità di Capienza',
        field: 'anagraficaQuantitaCapienza',
      },
      {
        headerName: 'Note CP',
        field: 'noteCp',
        editable: params =>
          DossierUnicoUtils.canEdit('noteCp', user, params.data),
      },
      {
        headerName: 'Note Merch',
        field: 'noteMerch',
        editable: params =>
          DossierUnicoUtils.canEdit('noteMerch', user, params.data),
      },
      {
        headerName: 'Note CS',
        field: 'noteCs',
        editable: params =>
          DossierUnicoUtils.canEdit('noteCs', user, params.data),
      },
      { field: 'removed', editable: false },
      { field: 'added', editable: false },
    ],
    [user, actionsMenuCellRenderer],
  )

  const handleCellValueChange = useCallback(e => {
    const { oldValue, colDef, data, node } = e
    const record = originalRecords.current.find(
      record => record.idReferenza === data.idReferenza,
    )
    const isSameValue = oldValue === data[colDef.field]
    const isOriginalValue = record?.[colDef.field] === data[colDef.field]

    if (isSameValue) return

    const changes = DossierUnicoUtils.getChanges(colDef.field, data[colDef.field], { data, user })

    Object.keys(changes).forEach(field => {
      node.setDataValue(field, changes[field])
    })

    setDataChanges(prev => {
      if (isOriginalValue) {
        return prev.filter(
          change =>
            change.idReferenza !== data.idReferenza ||
            change.field !== colDef.field,
        )
      }

      const existingChangeIndex = prev.findIndex(
        change =>
          change.idReferenza === data.idReferenza &&
          change.field === colDef.field,
      )

      if (existingChangeIndex > -1) {
        return [
          ...prev.slice(0, existingChangeIndex),
          {
            ...prev[existingChangeIndex],
            newValue: data[colDef.field],
            timestamp: Date.now(),
          },
          ...prev.slice(existingChangeIndex + 1),
        ]
      }

      return [
        ...prev,
        {
          idReferenza: data.idReferenza,
          field: colDef.field,
          newValue: data[colDef.field],
          timestamp: Date.now(),
        },
      ]
    })
  }, [])

  const handleSelectionChange = useCallback(event => {
    setSelectedRecords(event.api.getSelectedNodes())
  }, [])

  const handleFirstDataRendered = useCallback(params => {
    if (user?.role === ROLE_CS) {
      params.api.setFilterModel({
        stato: {
          type: 'set',
          values: ['Confermato Sede'],
        }
      });
      params.api.onFilterChanged();
    }
  }, [user?.role])

  const handleRowDataUpdated = useCallback(params => {
    refreshProductIssues();
  }, [refreshProductIssues])

  const handleDeleteSelectedRecords = useCallback(async () => {
    if (selectedRecords.length === 0) return
    await api.dossierUnico.deleteProducts(
      dossier.id,
      selectedRecords.map(node => node.data.idReferenza),
    )
    message.success('Prodotti eliminati con successo')
    gridApi.current.applyTransaction({
      remove: selectedRecords.map(node => ({
        idReferenza: node.data.idReferenza,
      })),
    })
  }, [dossier.id, selectedRecords])

  const handleIsRowSelectable = useCallback(params => {
    return DossierUnicoUtils.canDeleteRecord(params.data)
  }, [])

  const handleAddRecords = useCallback(products => {
    gridApi.current.applyTransaction({
      add: products,
    })
  }, [])

  const getRowStyle = useCallback(params => {
    const style = {}

    if (params.data.removed) {
      style.backgroundColor = 'rgba(204,51,51,0.25)'
    } else if (params.data.added) {
      style.backgroundColor = 'rgba(102,204,51,0.25)'
    }

    return style
  }, [])

  const handleUpdateRecords = useCallback(
    async (updatedProducts, resetDataChanges = false) => {
      updatedProducts.forEach(updatedProduct => {
        const node = gridApi.current.getRowNode(updatedProduct.idReferenza)
        if (!node) return
        node.setData({
          ...node.data,
          ...updatedProduct,
        })
      })

      if (resetDataChanges) {
        setDataChanges([])
      }
    },
    [],
  )

  const handleCloseProductsModal = useCallback(() => {
    workingRecord.current = null
    setShowProductsModal(false)
  }, [])

  const handleConfirmProductsModal = useCallback(
    async productIds => {
      try {
        const { data } = await api.dossierUnico.replaceProduct(dossier.id, {
          replacedProductId: workingRecord.current.data.idReferenza,
          replacementProductId: Array.from(productIds)[0],
          replacementType: 'sostituita',
        })

        workingRecord.current.setData(data[0])
        gridApi.current.applyTransaction({
          add: [data[1]],
        })
      } catch (e) {
        console.error(e)
        message.error("Errore durante l'aggiunta dei prodotti")
      } finally {
        setShowProductsModal(false)
      }
    },
    [dossier.id],
  )

  const toggleProductErrorsModal = useCallback(() => {
    setProductErrorsModal(prev => !prev)
  }, [])

  return (
    <Row type="flex" style={{ marginTop: '16px', flexGrow: 1 }}>
      <Col xs={24} className={flexClass}>
        <DossierUnicoDataToolbar
          dataChanges={dataChanges}
          hideHeader={hideHeader}
          setHideHeader={setHideHeader}
          dossierId={dossier.id}
          selectedRecords={selectedRecords}
          onDeleteSelectedRecords={handleDeleteSelectedRecords}
          onAddRecords={handleAddRecords}
          onUpdateRecords={handleUpdateRecords}
          toggleProductErrorsModal={toggleProductErrorsModal}
          productIssues={productIssues}
        />
        <div
          className={`${css`
            width: 100%;
            height: 100%;
            flex-grow: 1;
          `} ag-theme-alpine`}>
          <AgGridReact
            onGridReady={onGridReady}
            onModelUpdated={onModelUpdated}
            columnDefs={gridColumns}
            rowData={records}
            rowSelection="multiple"
            suppressRowClickSelection
            localeText={AG_GRID_LOCALE_IT}
            getRowId={params => params.data.idReferenza}
            enableRangeSelection
            onCellValueChanged={handleCellValueChange}
            onSelectionChanged={handleSelectionChange}
            isRowSelectable={handleIsRowSelectable}
            onFirstDataRendered={handleFirstDataRendered}
            onRowDataUpdated={handleRowDataUpdated}
            getRowStyle={getRowStyle}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true,
              cellRenderer: defaultCellRenderer,
              cellEditor: AgTextCellEditor,
              floatingFilter: true,
            }}
          />
        </div>
        <SearchProductsModal
          visible={showProductsModal}
          onHide={handleCloseProductsModal}
          onConfirm={handleConfirmProductsModal}
          multiple={false}
        />
        <Drawer
          title="Problemi di validazione"
          placement="right"
          closable={true}
          onClose={() => {
            setProductErrorsModal(false)
          }}
          visible={showProductErrorsModal}
          width="90%">
          <List>
            {Object.keys(productIssues).map(idReferenza => (
              <List.Item key={idReferenza}>
                <List.Item.Meta
                  title={`${idReferenza} - ${productIssues[idReferenza].record.anagraficaDescrizione}`}
                  description={productIssues[idReferenza].issues.map(
                    (issue, index) => (
                      <Alert
                        showIcon
                        style={{ marginTop: index > 0 ? '8px' : 0 }}
                        type={issue.type}
                        message={
                          <>
                            <strong>{issue.field}</strong>:{' '}
                            <span>{issue.message}</span>
                          </>
                        }
                      />
                    ),
                  )}
                />
              </List.Item>
            ))}
          </List>
        </Drawer>
      </Col>
    </Row>
  )
}

export default DossierUnicoData
