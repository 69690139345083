import React, { useCallback, useState } from 'react'
import { Alert, AutoComplete, Button, Modal } from 'antd'
import ProductsFilterForm from 'Components/DossierUnico/ProductsFilterForm'
import api from 'Common/api'
import ProductsLookupResults from 'Components/DossierUnico/ProductsLookupResults'

const SearchDossierUnicoModal = props => {
  const { visible, onHide, onConfirm } = props
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)

  // const handleSearch = useCallback(async values => {
  //   const { data } = await api.products.lookup({
  //     ...values,
  //     scope: 'dossier-unico',
  //     metadata: {
  //       dossierUnicoId: '1',
  //     },
  //   })
  //
  //   setSearchResults(data)
  // }, [])

  const handleOk = async () => {}

  const handleSearch = searchText => {
    if (Number.isNaN(Number(searchText)) && searchText.length < 3) return

    api.dossierUnico.search(searchText).then(({ data }) => {
      console.log(data);
    }).catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1500);
  }

  return (
    <Modal
      title="Trasferisci prodotti in un altro dossier"
      visible={visible}
      onOk={handleOk}
      onCancel={onHide}
      okText="Trasferisci"
      okButtonProps={{ disabled: true }}
      cancelText="Annulla"
      centered
      width="90%"
      style={{ maxWidth: 1000 }}>
      <AutoComplete
        style={{ width: '100%' }}
        placeholder="Inizia a digitare codice o descrizione..."
        onSearch={handleSearch}
      />
      {loading && <Alert style={{ marginTop: '16px' }} message="Non ci sono risultati per la ricerca" type="info" showIcon />}
    </Modal>
  )
}

export default SearchDossierUnicoModal
