import React, { useCallback, useState } from 'react'
import { Button, Modal } from 'antd'
import ProductsFilterForm from 'Components/DossierUnico/ProductsFilterForm'
import api from 'Common/api'
import ProductsLookupResults from 'Components/DossierUnico/ProductsLookupResults'

const SearchProductsModal = props => {
  const { visible, onHide, onConfirm, multiple } = props
  const [searchResults, setSearchResults] = useState([])
  const [selectedProducts, setSelectedProducts] = useState(new Set())
  const [isAdding, setIsAdding] = useState(false)

  const handleSearch = useCallback(async values => {
    const { data } = await api.products.lookup({
      ...values,
      scope: 'dossier-unico',
      metadata: {
        dossierUnicoId: '1',
      },
    })

    setSearchResults(data)
  }, [])

  const handleProductSelection = useCallback(productId => {
    setSelectedProducts(prev => {
      const updatedValue = new Set(Array.from(prev))
      if (updatedValue.has(productId)) {
        updatedValue.delete(productId)
      } else {
        updatedValue.add(productId)
      }

      return updatedValue
    })
  }, [])

  const handleOk = async () => {
    setIsAdding(true)
    await onConfirm(selectedProducts)
    setIsAdding(false)
  }

  return (
    <Modal
      title="Cerca e aggiungi prodotti"
      visible={visible}
      onOk={handleOk}
      onCancel={onHide}
      okText="Aggiungi"
      okButtonProps={{
        disabled: selectedProducts.size === 0,
        loading: isAdding,
      }}
      cancelText="Annulla"
      centered
      width="90%"
      style={{ maxWidth: 1000 }}>
      <ProductsFilterForm onSearch={handleSearch} />
      {searchResults.length > 0 && (
        <div style={{ marginTop: '16px' }}>
          <ProductsLookupResults
            results={searchResults}
            selected={selectedProducts}
            onSelection={handleProductSelection}
            multiple={multiple}
            clearAll={() => setSelectedProducts(new Set())}
            addAll={() => setSelectedProducts(new Set(searchResults.map(p => p.id)))}
          />
        </div>
      )}
    </Modal>
  )
}

export default SearchProductsModal
