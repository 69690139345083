import React, { useState } from 'react'
import { Button, Input, message, Table, Tooltip } from 'antd'
import moment from 'moment'
import DossierUnicoUtils from 'Components/DossierUnico/DossierUnicoUtils'
import { useSelector } from 'react-redux'
import { ROLE_CP } from 'Common/constants/global'
import { css } from '@emotion/css'
import api from 'Common/api'

const DossierUnicoHeader = ({ dossier, updateDossier }) => {
  const user = useSelector(state => state.User)
  const [editing, setEditing] = useState(null);
  const [editValue, setEditValue] = useState(null);

  const handleChange = (event) => {
    setEditValue(event.target.value);
  };

  const handleSave = (event) => {
    if (event && event.key !== 'Enter') {
      return;
    }

    api.dossierUnico.update(dossier.id, {
      ...dossier,
      merchRiferimento: editValue,
    }).then((result) => {
      message.success('Salvataggio avvenuto con successo');
      setEditing(null);
      setEditValue(null);
      updateDossier(result.data);
    }).catch((error) => {
      message.error('Errore durante il salvataggio');
      console.error(error);
    });
  };

  return (
    <>
      <Table
        style={{ width: '100%' }}
        bordered={true}
        columns={[
          {
            title: 'Nome',
            dataIndex: 'nome',
            width: '25%',
            render: (value, record) => `${record.idFamiglia} - ${value}`
          },
          {
            title: 'Stato',
            dataIndex: 'stato',
            width: '25%',
            render: value => DossierUnicoUtils.translateStatus(value)
          },
          {
            title: 'Ultimi aggiornamenti',
            dataIndex: 'stato',
            width: '25%',
            render: (_, record) => (
              <>
                <div>
                  <Tooltip title={`Creato da ${record.creatoDa}`}>
                    <span
                      style={{ cursor: 'default' }}>Creazione: {moment(record.dataCreazione).format('DD/MM/YYYY HH:mm:ss')}</span>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title={`Modificato da ${record.ultimaModificaDa}`} placement="bottom">
                    <span
                      style={{ cursor: 'default' }}>Ultima modifica: {moment(record.dataUltimaModifica).format('DD/MM/YYYY HH:mm:ss')}</span>
                  </Tooltip>
                </div>
              </>
            )
          },
          {
            title: 'Merch. rif',
            dataIndex: 'merchRiferimento',
            width: '25%',
            render: (value, record) => {
              if (editing === 'merchRiferimento') {
                return (
                  <div className={css`
                      display: flex;
                      align-items: center;
                  `}>
                    <Input width="100%" value={editValue} onChange={handleChange} onBlur={() => handleSave()} onKeyPress={handleSave} />
                  </div>
                );
              }

              if (user?.role === ROLE_CP) {
                return (
                  <div className={css`
                      display: flex;
                      align-items: center;
                  `}>
                    {user?.role === ROLE_CP && (
                      <Button type="link" icon="edit" style={{ marginRight: '16px' }} onClick={() => {
                        setEditing('merchRiferimento');
                        setEditValue(dossier.merchRiferimento);
                      }} />
                    )}
                    <span>{value}</span>
                  </div>
                );
              }

              return value;
            }


          },
        ]}
        dataSource={[
          dossier
        ]}
        pagination={false}
        size='small'
      />
    </>
  );
};

export default DossierUnicoHeader;
